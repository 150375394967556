<template>
  <div>
    <div v-if="!preview_evaluation">
      <b-form-checkbox
        v-if="allows_crud"
        v-model="limited_words_amount"
        @change="changeLimitWordsAmount"
        switch
      >
        Limitar longitud de respuesta.
      </b-form-checkbox>
      <div
        class="limit-container mt-1"
        v-if="limited_words_amount && allows_crud"
      >
        <div class="selector-container">
          <div style="width: 240px">
            Limitar por:
            <b-form-select
              v-model="Question.max_answer_length_type"
              :options="maxAnswerLengthType"
              text-field="value"
              value-field="id"
              size="sm"
              class="ml-0"
              style="width: 120px"
              @change="patchQuestionWordAmountType"
            >
            </b-form-select>
          </div>
        </div>
        <div class="input-container">
          <div
            class="answer_type"
            v-if="Question.max_answer_length_type != null"
          >
            <label> Establecer límite:</label>
            <b-form-input
              type="number"
              size="sm"
              class="max-answer-length ml-2 mb-2"
              v-model.number="Question.max_answer_length"
              @change="patchQuestionWordAmount"
            ></b-form-input>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="preview_evaluation && AnswerLengthType != null">
      <div class="textarea-container mb-0">
        Limitar longitud de respuesta en {{ Question.max_answer_length }}
        {{ AnswerLengthType }}.
      </div>
      <div></div>
    </div>
    <div class="text-here ml-5" v-if="!preview_evaluation">
      <span class="align-self-center noprint">Texto de respuesta aquí</span>
    </div>
    <div v-else-if="preview_evaluation && !evaluatee_view && !evaluator_view">
      <div class="textarea-container">
        <b-form-textarea
          class="textarea-answer"
          v-model="preview_text"
          :state="ValidPreviewText"
          aria-describedby="input-text-feedback"
        >
        </b-form-textarea>
        <b-form-invalid-feedback id="input-text-feedback" class="noprint">
          La respuesta supera límite establecido por el Docente.
        </b-form-invalid-feedback>
      </div>
    </div>
    <div v-else-if="evaluatee_view && is_running_test && !finished_test">
      <div class="textarea-container">
        <b-form-textarea
          class="textarea-answer"
          v-model="question_score.answer"
          :state="ValidPreviewText"
          @input="debouncePatchScore"
          aria-describedby="input-text-feedback"
        >
        </b-form-textarea>
        <b-form-invalid-feedback id="input-text-feedback" class="noprint">
          <div v-if="question_score.answer.length == 0">
            La respuesta se encuentra vacía.
          </div>
          <div v-else>
            La respuesta supera límite establecido por el Docente.
          </div>
        </b-form-invalid-feedback>
      </div>
    </div>
    <div v-else-if="(evaluatee_view && finished_test) || evaluator_view">
      <div class="textarea-container">
        <b-form-textarea
          class="textarea-answer"
          v-model="question_score.answer"
          :disabled="true"
          aria-describedby="input-text-feedback"
        >
        </b-form-textarea>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as names from "@/store/names";
import { generateUniqueId, toast } from "@/utils/utils";

export default {
  name: "LimitQuestion",
  props: {
    Question: {
      type: Object,
      required: true,
    },
    QuestionScore: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          answer: "",
          evaluatee: null,
          redaction_question: this.Question.id,
          chosen_rubric: null,
          attachments: [],
        };
      },
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
    preview_evaluation: {
      type: Boolean,
      required: true,
    },
    evaluatee_view: {
      type: Boolean,
      default: false,
    },
    evaluator_view: {
      type: Boolean,
      default: false,
    },
    is_running_test: {
      type: Boolean,
      default: false,
    },
    finished_test: {
      type: Boolean,
      default: false,
    },
    // para mostrar el resultado del evaluado al profesor.
    user_id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      limited_words_amount: false,
      preview_text: "",
      question_score: { ...this.QuestionScore },
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      max_answer_length_types: names.REDACTION_QUESTION_LENGTH_ANSWERS,
    }),
    maxAnswerLengthType() {
      let max_answer_length_types = [...this.max_answer_length_types];
      max_answer_length_types.push({ id: null, value: "N/A" });
      return max_answer_length_types;
    },
    AnswerLengthType() {
      const type = this.max_answer_length_types.find(
        (x) => x.id == this.Question.max_answer_length_type
      );
      if (type) return type.value;
      else return null;
    },
    ValidPreviewText() {
      if (this.AnswerLengthType != null) {
        let max_answer_length = 0;
        if (this.Question && this.Question.max_answer_length)
          max_answer_length = this.Question.max_answer_length;
        if (this.preview_evaluation && !this.evaluatee_view) {
          if (this.$equals(this.AnswerLengthType, "Párrafos")) {
            const parrafos = this.preview_text
              .split("\n")
              .filter((para) => para.trim() !== "");
            if (parrafos.length <= max_answer_length) return true;
            else return false;
          } else if (this.$equals(this.AnswerLengthType, "Caracteres")) {
            const caracteres = this.preview_text.replace(/\s/g, "").length;
            if (caracteres <= max_answer_length) return true;
            else return false;
          } else if (this.$equals(this.AnswerLengthType, "Palabras")) {
            const palabras = this.preview_text
              .split(/\s+/)
              .filter((word) => word !== "").length;
            if (palabras <= max_answer_length) return true;
            else return false;
          }
        } else if (this.evaluatee_view) {
          if (
            !isNaN(this.question_score.id) &&
            this.question_score.answer.trim() == ""
          )
            return false;
          else if (this.$equals(this.AnswerLengthType, "Párrafos")) {
            const parrafos = this.question_score.answer
              .split("\n")
              .filter((para) => para.trim() !== "");
            if (parrafos.length <= max_answer_length) return true;
            else return false;
          } else if (this.$equals(this.AnswerLengthType, "Caracteres")) {
            const caracteres = this.question_score.answer.replace(
              /\s/g,
              ""
            ).length;
            if (caracteres <= max_answer_length) return true;
            else return false;
          } else if (this.$equals(this.AnswerLengthType, "Palabras")) {
            const palabras = this.question_score.answer
              .split(/\s+/)
              .filter((word) => word !== "").length;
            if (palabras <= max_answer_length) return true;
            else return false;
          }
        }
      } else {
        if (
          !isNaN(this.question_score.id) &&
          this.question_score.answer.trim() == ""
        )
          return false;
        return true;
      }
      return true;
    },
  },
  methods: {
    patchQuestion(item) {
      if (this.Question.question_type == 1) {
        const payload = {
          new_redaction_question_id: this.Question.id,
          item: item,
        };
        this.$store.dispatch(names.PATCH_NEW_REDACTION_QUESTION, payload);
      }
      toast("Pregunta actualizada.");
    },
    changeLimitWordsAmount(value) {
      if (value == false) {
        this.patchQuestion({
          max_answer_length: 0,
          max_answer_length_type: null,
        });
      }
    },
    patchQuestionWordAmountType(value) {
      if (value == 0 || value == null)
        this.patchQuestion({
          max_answer_length: 0,
          max_answer_length_type: null,
        });
      else {
        this.patchQuestion({ max_answer_length_type: value });
      }
    },
    patchQuestionWordAmount(value) {
      if (value >= 0 && value != null && value != "") {
        if (this.timeout2) clearTimeout(this.timeout2);
        this.timeout2 = setTimeout(() => {
          this.patchQuestion({ max_answer_length: value });
        }, 1000);
      }
    },
    debouncePatchScore() {
      if (
        this.ValidPreviewText ||
        (this.question_score && this.question_score.answer == "")
      ) {
        if (this.timeout2) clearTimeout(this.timeout2);
        this.timeout2 = setTimeout(() => {
          if (
            this.ValidPreviewText ||
            (this.question_score && this.question_score.answer == "")
          )
            this.saveQuestionScore();
        }, 1000);
      }
    },
    saveQuestionScore() {
      if (this.question_score.evaluatee == this.user_id) {
        if (isNaN(this.question_score.id)) this.createdQuestionScore();
        else this.updatedQuestionScore();
      }
    },
    createdQuestionScore() {
      this.$store
        .dispatch(names.POST_EVALUATEE_REDACTION_QUESTION, this.question_score)
        .then((response) => {
          this.question_score = response;
          toast("Pregunta respondida.");
        });
    },
    updatedQuestionScore() {
      const payload = {
        evaluatee_redaction_question_id: this.question_score.id,
        item: {
          answer: this.question_score.answer,
        },
      };
      this.$store
        .dispatch(names.PATCH_EVALUATEE_REDACTION_QUESTION, payload)
        .then((response) => {
          this.question_score = response;
          toast("Pregunta respondida.");
        });
    },
  },
  watch: {},
  mounted() {
    if (isNaN(this.question_score.id) && this.user_id != null)
      this.question_score.evaluatee = this.user_id;
  },
  created() {
    if (
      this.Question.question_type == 1 &&
      this.Question.max_answer_length_type != null
    )
      this.limited_words_amount = true;
  },
};
</script>

<style scoped>
.textarea-answer {
  min-height: 7em;
}
.textarea-container {
  margin-bottom: 1em;
  margin-right: 2em;
  margin-left: 1em;
}
.limit-container {
  display: flex;
}
.selector-container {
  display: flex;
}
.input-container {
  display: flex;
}
.max-answer-length {
  width: 90px;
  min-height: 31px;
}
.text-here {
  height: 80px;
  text-align: left;
  margin-left: 1rem;
  display: flex;
}
.answer_type {
  display: flex;
  align-items: center;
}
@media (max-width: 750px) {
  .textarea-container {
    margin-bottom: 1em;
    margin-right: 10px;
    margin-left: 0px;
  }
  .limit-container {
    flex-direction: column;
  }
  .selector-container {
    margin-top: 1rem;
  }
  .input-container {
    margin-top: 1rem;
  }
  label {
    max-width: 140px;
  }
}
@media print {
  .textarea-answer {
    border: 1px solid #c9c9c9;
  }
  textarea.form-control.is-valid,
  textarea.form-control.is-invalid {
    background-image: none !important;
  }
}
</style>